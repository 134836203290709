import { ReactElement, useMemo } from 'react'

import ShopifyLogo from '@/assets/icons/shopifyLogo.svg?react'
import logoImg from '@/assets/logo.webp'
const shopifyLogo = <ShopifyLogo style={{ height: '1em', verticalAlign: 'text-top' }} />
const shipoLogo = <img src={logoImg} style={{ height: '1em', verticalAlign: 'text-top' }} />

/** 平台值:平台名称 */
export const PLAT_FORM_NAME_MAP: Record<number | string, string | undefined> = {
  1: 'shopify',
  2: 'shipo',
}
/** 平台值:Logo组件 */
export const PLAT_FORM_LOGO_MAP: Record<number | string, ReactElement | undefined | string> = {
  1: shopifyLogo,
  2: shipoLogo,
}
/** 平台选项列表 */
export const PLAT_FORM_OPTS = [
  { value: 1, label: shopifyLogo },
  { value: 2, label: shipoLogo },
]

/** 币种简称:币种全称 */
export const useCURRENCY_DES_MAP = (): Record<string, string | undefined> => {
  return useMemo(
    () => ({
      EUR: 'EUR - Euro',
      GBP: 'GBP - Great British Pound',
      CAD: 'CAD - Canadian dollar',
      USD: 'USD - United States Dollar',
      AUD: 'AUD - Australian Dollar',
      RMB: 'RMB',
    }),
    [],
  )
}
/** 币种选项列表 */
export const useCURRENCY_OPTS = (pickKeys = 'EUR,GBP,CAD,USD,AUD') => {
  const desMap = useCURRENCY_DES_MAP()
  return useMemo(() => {
    const keys = pickKeys.split(',')
    return Object.entries(desMap)
      .map(([value, label]) => ({ value, label }))
      .filter(opt => keys.includes(opt.value))
  }, [desMap, pickKeys])
}
